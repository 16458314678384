export const fonts = {
	bold: {
		fontFamily: 'Archivo, sans-serif',
		fontWeight: 700,
	},
	condensedBold: {
		fontFamily: 'Archivo, sans-serif',
		fontStretch: '75%',
		fontWeight: 700,
	},
	condensedBlack: {
		fontFamily: 'Archivo, sans-serif',
		fontStretch: '75%',
		fontWeight: 900,
	},
	medium: {
		fontFamily: 'Archivo, sans-serif',
		fontWeight: 500,
	},
}

const BHHeader = {
	BHHeader: {
		defaultProps: {
			changeCountryLanguageProps: {
				showFlag: false,
			},
		},
	},
}

export default BHHeader

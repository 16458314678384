import { fonts } from '../fonts'

const Tab = {
	MuiTab: {
		styleOverrides: {
			root: {
				...fonts.bold,
			},
		},
	},
}

export default Tab

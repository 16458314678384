import defaultTheme from '@bluheadless/ui/src/theme/default'

const AccordionDetails = {
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				color: '#000',
				fontSize: 16,
				lineHeight: 3 / 2,
				padding: '0 0 46px',
				[defaultTheme.breakpoints.up('md')]: {
					lineHeight: 15 / 8,
				},
			},
		},
	},
}

export default AccordionDetails

import { fonts } from '../fonts'

const InputLabel = {
	MuiInputLabel: {
		styleOverrides: {
			root: {
				...fonts.bold,
				color: '#000',
				fontSize: 14,
				letterSpacing: 0.28,
			},
		},
	},
}

export default InputLabel

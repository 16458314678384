const BHRelatedAttributes = {
	BHRelatedAttributes: {
		defaultProps: {
			showCurrentLabel: true,
			showLabel: true,
		},
	},
}

export default BHRelatedAttributes

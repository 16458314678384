import Bag2Icon from '@bluheadless/ui/src/atoms/icons/bag-2-icon'

const BHTileBase = {
	BHTileBase: {
		defaultProps: {
			wishListProps: {
				position: 'outer',
			},
			addToCartProps: {
				position: 'outer',
				variant: 'icon',
				Icon: Bag2Icon,
				iconProps: { color: 'primary', fontSize: 'button' },
			},
		},
	},
}

export default BHTileBase

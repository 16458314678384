import { FormattedMessage } from 'react-intl'

const BHViewMode = {
	BHViewMode: {
		defaultProps: {
			label: <FormattedMessage id="category_view_mode" />,
			variant: 'alternate',
		},
	},
}

export default BHViewMode

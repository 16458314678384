const Breadcrumbs = {
	MuiBreadcrumbs: {
		defaultProps: {
			separator: '>',
		},
		styleOverrides: {
			root: {
				color: '#afafaf',
			},
			separator: { margin: '0 16px' },
		},
	},
}

export default Breadcrumbs

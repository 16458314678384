const AccordionSummary = {
	MuiAccordionSummary: {
		styleOverrides: {
			root: {
				padding: '29px 0',
			},
		},
	},
}

export default AccordionSummary

import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import { createTheme } from '@mui/system'
import AccordionDetails from './theme/components/accordion-details'
import AccordionSummary from './theme/components/accordion-summary'
import BHCrossSell from './theme/components/bh-crosssell'
import BHHeader from './theme/components/bh-header'
import BHRelated from './theme/components/bh-related'
import BHRelatedAttributes from './theme/components/bh-related-attributes'
import BHTileBase from './theme/components/bh-tile-base'
import BHUpsells from './theme/components/bh-upsells'
import BHViewMode from './theme/components/bh-view-mode'
import Breadcrumbs from './theme/components/breadcrumbs'
import Button from './theme/components/button'
import CssBaseline from './theme/components/css-baseline'
import InputLabel from './theme/components/input-label'
import OutlinedInput from './theme/components/outlined-input'
import Tab from './theme/components/tab'
import { fonts } from './theme/fonts'

const muiTheme = createMuiTheme({ typography: { fontSize: 14, htmlFontSize: 14 } })

const fontFamily = 'Archivo, sans-serif'
const fontFamilyAlternative = 'Archivo, sans-serif'

const theme = createTheme(defaultTheme, {
	palette: {
		black: { main: '#000' },
		common: { black: '#000' },
		footer: {
			bgBottom: '#000',
			bgMiddle: '#000',
			bgMiddleBottom: '#000',
			bgMiddleTop: '#000',
			border: '#e1e1e1',
		},
		primary: {
			main: '#000',
		},
		promo: '#ff00b6',
	},
	shape: {
		borderRadius: 0,
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		fontStretchCondensed: '75%',
		fontWeight: 500,
		fontWeightBold: 700,
		fontWeightCondensedBlack: 900,
		body1: {
			...fonts.medium,
		},
		body2: {
			...fonts.bold,
		},
		caption: {
			...fonts.medium,
		},
		headlinesmall: {
			...fonts.condensedBlack,
			fontSize: muiTheme.typography.pxToRem(30),
			letterSpacing: 0,
			lineHeight: 4 / 3,
		},
		headlinebig: {
			...fonts.condensedBlack,
			fontSize: muiTheme.typography.pxToRem(30),
			lineHeight: 'calc(4/3)',
			[defaultTheme.breakpoints.up('md')]: {
				fontSize: muiTheme.typography.pxToRem(80),
				lineHeight: 1,
			},
		},
		h1: {
			...fonts.condensedBold,
			fontSize: muiTheme.typography.pxToRem(40),
			fontWeight: 900,
		},
		h2: {
			...fonts.condensedBold,
		},
		h3: {
			...fonts.condensedBold,
		},
		h4: {
			...fonts.condensedBold,
		},
		h5: {
			...fonts.condensedBold,
		},
		h6: {
			...fonts.condensedBold,
		},
		p: {
			...fonts.medium,
			lineHeight: 1.5,
		},
		subheadline1: {
			...fonts.medium,
		},
		subheadline2: {
			...fonts.medium,
			lineHeight: 1.5,
			[defaultTheme.breakpoints.down('md')]: {
				fontSize: muiTheme.typography.pxToRem(16),
			},
		},
		subtitle1: {
			...fonts.medium,
			lineHeight: 10 / 7,
			[defaultTheme.breakpoints.up('md')]: {
				fontSize: muiTheme.typography.pxToRem(16),
				lineHeight: 1.25,
			},
		},
		subtitle2: {
			...fonts.medium,
			lineHeight: 15 / 8,
		},
	},
	linkHover: `
	 	position: relative;
		&:after {
			background-color: currentcolor;
			bottom: 0;
			content:'';
			display: block;
			height: 1px;
			position: absolute;
			transition: 0.3s width;
			left: 0;
			width: 0;
		}
		&:hover:after {
			width: 100%;
		}
	`,
	components: {
		...AccordionDetails,
		...AccordionSummary,
		...BHCrossSell,
		...BHHeader,
		...BHRelated,
		...BHRelatedAttributes,
		...BHTileBase,
		...BHUpsells,
		...BHViewMode,
		...Breadcrumbs,
		...Button,
		...CssBaseline,
		...InputLabel,
		...OutlinedInput,
		...Tab,
	},
})

export default theme

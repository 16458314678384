import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			body: {
				...fonts.medium,
				color: '#000',
			},
		},
	},
}

export default CssBaseline

const OutlinedInput = {
	MuiOutlinedInput: {
		styleOverrides: {
			notchedOutline: {
				borderColor: '#afafaf',
			},
		},
	},
}

export default OutlinedInput
